import { Card } from "@streets-heaver/shui2";
import classes from "./Widget.module.scss";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Widget({ automationId, className, hasBorder = false, children }) {
	return (
		<Card automationId={automationId} border={hasBorder} className={clsx(classes.widget, className)}>
			{children}
		</Card>
	);
}

Widget.Header = function WidgetHeader({ text, icon }) {
	return (
		<div className={classes.header}>
			<div className={classes.text}>{text}</div>
			<div className={classes.icon}>{icon}</div>
		</div>
	);
};

Widget.Content = function WidgetContent({ children }) {
	return <div className={classes.content}>{children}</div>;
};

Widget.Button = function WidgetButton({ children, onClick, innerRef, automationId }) {
	return (
		<button type="button" onClick={onClick} className={classes.button} ref={innerRef} data-testid={automationId}>
			{children}
		</button>
	);
};

Widget.Link = function WidgetLink({ className, ...props }) {
	return <NavLink className={clsx(classes.link, className)} {...props} />;
};

Widget.Title = function WidgetTitle({ children, className }) {
	return <p className={clsx(classes.title, className)}>{children}</p>;
};

Widget.Locked = function WidgetLocked({ icon, colour, title, subtitle }) {
	return (
		<div className={classes.locked}>
			<div className={classes.lockedTitles}>
				{icon && (
					<FontAwesomeIcon
						icon={icon}
						className={classes.icon}
						style={{
							"--fa-primary-color": colour,
							"--fa-secondary-color": colour,
						}}
					/>
				)}
				{title && <p className={classes.title}>{title}</p>}
			</div>
			{subtitle && <p className={classes.subtitle}>{subtitle}</p>}
		</div>
	);
};
