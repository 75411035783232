import { useMsal } from "@azure/msal-react";
import { JournalAPIProvider } from "@streets-heaver/journal";
import { useDataSources } from "@streetsheaver/compucore";
import { getJournalEntries } from "../api/journal/getJournalEntries";
import { getReadingPaneForAttachment } from "../api/journal/getReadingPaneForAttachment";
import { getReadingPaneForNote } from "../api/journal/getReadingPaneForNote";
import { getJournalBlob } from "../api/journal/getJournalBlob";

export function ClinicianJournalAPIProvider({ children }) {
	const { currentDataSource } = useDataSources();
	const dataSourceId = currentDataSource?.DataSourceId;
	const { instance } = useMsal();

	if (!dataSourceId) throw new Error("No datasource id found.");

	const context = {
		getJournalEntries: (patientId) => getJournalEntries(instance, { dataSourceId, patientId }),
		getReadingPaneForAttachment: (attachmentId) =>
			getReadingPaneForAttachment(instance, { dataSourceId, attachmentId }),
		getReadingPaneForNote: (noteId) => getReadingPaneForNote(instance, { dataSourceId, noteId }),
		getBlob: (blobStorageId) => getJournalBlob(instance, { dataSourceId, blobStorageId }),
	};

	return <JournalAPIProvider {...context}>{children}</JournalAPIProvider>;
}
