import { faBook, faCalendarDays, faClipboardList, faUsersRectangle } from "@shfortawesome/pro-duotone-svg-icons";
import { faPlus } from "@shfortawesome/pro-regular-svg-icons";

export const apps = (userSecurityFlags) => {
	const clinicianPages = [];

	if (userSecurityFlags?.includes(`mypatients`))
		clinicianPages.push({
			id: "my-patients",
			primaryText: "My patients",
			secondaryText: "Open",
			icon: faUsersRectangle,
			trackLastUsed: true,
			type: "link",
			to: "mypatients",
		});

	if (userSecurityFlags?.includes(`patient-addpatient`))
		clinicianPages.push({
			id: "add-patient",
			primaryText: "Add new patient",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-patient",
		});

	if (userSecurityFlags?.includes(`orders`))
		clinicianPages.push({
			id: "orders",
			primaryText: "Orders",
			secondaryText: "Open",
			icon: faClipboardList,
			trackLastUsed: true,
			type: "link",
			to: "orders",
		});

	if (userSecurityFlags?.includes(`scheduler`))
		clinicianPages.push({
			id: "scheduler",
			primaryText: "Scheduler",
			secondaryText: "Open",
			icon: faCalendarDays,
			trackLastUsed: true,
			type: "link",
			to: "scheduler",
		});

	if (userSecurityFlags?.includes(`appointment-addappointment`))
		clinicianPages.push({
			id: "add-appointment-new",
			primaryText: "Add new appointment",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-appointment/new",
		});

	if (userSecurityFlags?.includes(`appointment-addappointment`))
		clinicianPages.push({
			id: "add-appointment-follow-up",
			primaryText: "Add follow-up appointment",
			secondaryText: "Add",
			icon: faPlus,
			menuItemOnly: true,
			trackLastUsed: true,
			type: "link",
			to: "add-appointment/follow-up",
		});

	return clinicianPages;
};

export const globalSearchApps = (datasource, pagepath) => [
	{
		displayName: "My patients",
		icon: faUsersRectangle,
		id: "mypatients",
		path: `../${datasource}/mypatients`,
	},
	{ displayName: "Orders", icon: faClipboardList, id: "orders", path: `../${datasource}/orders` },
	{
		displayName: "Scheduler",
		icon: faCalendarDays,
		id: "scheduler",
		path: `../${datasource}/scheduler`,
	},
	{
		displayName: "Journal",
		icon: faBook,
		id: "journal",
		path: `${pagepath.slice(0, 2).join("/")}/journal`,
		isPatientsApp: true,
	},
];
