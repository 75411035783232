import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { convertBlobToImage } from "../../utils";
import { useMsal } from "@azure/msal-react";

export const usePatientImage = (enabled, patientId, isThumbnail = false) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useQuery({
		queryKey: ["patientImage", enabled, patientId],
		queryFn: async () => {
			const data = await makeApiRequest(
				instance,
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/patient/${patientId}/image?thumbnail=${isThumbnail}`,
			);
			if (data?.request?.response) throw new Error(`Getting patient image for patient ${patientId} Failed`);
			return data;
		},
		select: convertBlobToImage,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && enabled,
	});

	return query;
};
