import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter as fadFilter } from "@shfortawesome/pro-duotone-svg-icons";
import { faFilter as falFilter, faMagnifyingGlass as falMagnifyingGlass } from "@shfortawesome/pro-light-svg-icons";
import { faFilter as farFilter, faMagnifyingGlass as farMagnifyingGlass } from "@shfortawesome/pro-regular-svg-icons";
import { faFilter as fasFilter, faMagnifyingGlass as fasMagnifyingGlass } from "@shfortawesome/pro-solid-svg-icons";
import { useAutoUpdateLastUsedAction } from "@streets-heaver/core";
import { Button, Textbox } from "@streets-heaver/shui2";
import { PreviewPanel, ScreenSize, useContentSizeClass, usePreviewPanel } from "@streetsheaver/compucore";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { useOrders } from "../../api/hooks/useOrders";
import { ExpandingOrderRow, InfoTable, NoAccessPage } from "../../components";
import { Filter } from "../../components/FiltersDialog/Filter";
import { OrdersFilterDialog } from "../../components/FiltersDialog/OrdersFilterDialog/OrdersFilterDialog";
import { FiltersList } from "../../components/FiltersList/FiltersList";
import { ReviewResultsPanel } from "../../components/ReviewResultsPanel/ReviewResultsPanel";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { shortDateFormat } from "../../utils";
import { desktopOrdersColumns, mobileOrdersColumns } from "../../utils/getColumns";
import { useFilterList } from "../../utils/hooks/useFilterList";
import classes from "./Orders.module.scss";
import { validateFilters } from "./validateFilters";

export default function Orders() {
	useAutoUpdateLastUsedAction("orders");

	const [filtersPopupOpen, setFiltersPopupOpen] = useState(false);
	const [isSearchboxVisible, setIsSearchboxVisible] = useState(false);
	const [searchText, setSearchText] = useState("");
	const [debouncedSearchText, setDebouncedSearchText] = useState("");

	const searchBoxRef = useRef(null);

	useEffect(() => {
		const debounceTimer = setTimeout(() => setDebouncedSearchText(searchText), 500);

		return () => {
			clearTimeout(debounceTimer);
		};
	}, [searchText]);

	useEffect(() => {
		searchBoxRef?.current?.focus();
	}, [isSearchboxVisible]);

	const initialFilters = {
		hideSeen: new Filter([false], {
			toStringFunction: () => "Hide seen",
		}),
		hideInProgress: new Filter([false], {
			toStringFunction: () => "Hide in progress",
		}),
		dateFrom: new Filter([], {
			toStringFunction: (values) => `From = ${shortDateFormat(values[0])}`,
		}),
	};

	const { appliedFilters, updateAppliedFilters } = useFilterList(initialFilters, validateFilters);
	const changedFiltersCount = Object.values(appliedFilters).filter((f) => !f.isDefault()).length;

	const { userSecurityFlags } = useCurrentClinicians();
	const { setIsVisible: setPreviewPanelVisible, setIsSheetMaximised } = usePreviewPanel();
	const tableRef = useRef(null);
	const width = useContentSizeClass();
	const query = useOrders(
		appliedFilters,
		width >= ScreenSize.TabletPortrait && debouncedSearchText.trim() !== "" ? debouncedSearchText.trim() : undefined,
		Math.ceil(tableRef?.current?.offsetHeight / 48),
		userSecurityFlags.includes(`orders`),
	);
	const navigate = useNavigate();
	const { patientId } = useParams();

	useEffect(() => {
		if (patientId) {
			setPreviewPanelVisible(true);
			setIsSheetMaximised(true);
		}
	}, [patientId, setPreviewPanelVisible, setIsSheetMaximised]);

	return (
		<div className={classes.orders} data-testid={"pageOrders"}>
			<Outlet />
			<PreviewPanel
				onBackButtonClick={
					patientId
						? () => {
								navigate("../orders");
							}
						: undefined
				}
				title={patientId && "Review results"}
			>
				{patientId && <ReviewResultsPanel patientId={patientId} />}
			</PreviewPanel>
			<div className={classes.body}>
				{!userSecurityFlags.includes(`orders`) ? (
					<NoAccessPage />
				) : (
					<>
						{width >= ScreenSize.TabletPortrait && (
							<div className={classes.filtersBar}>
								<FiltersList
									appliedFilters={appliedFilters}
									shownCondition={(_, filter) => !filter.isDefault()}
									updateAppliedFilters={updateAppliedFilters}
								/>
								<div className={classes.filtersBarButtons}>
									{isSearchboxVisible ? (
										<div className={classes.searchBox}>
											<Textbox
												reference={searchBoxRef}
												onBlur={() => {
													if (searchText.trim() === "") setIsSearchboxVisible(false);
												}}
												onChange={(e) => setSearchText(e.target.value)}
												icon={<FontAwesomeIcon icon={farMagnifyingGlass} />}
												type="filledLighter"
												size="large"
												value={searchText}
												automationId="order-search"
											/>
										</div>
									) : (
										<Button
											onClick={() => setIsSearchboxVisible(true)}
											size="large"
											type="subtle"
											colour="grey"
											icon={{
												restIcon: falMagnifyingGlass,
												hoverIcon: farMagnifyingGlass,
												pressedIcon: fasMagnifyingGlass,
												iconSecondaryColour: "var(--foregroundBrandOne)",
											}}
											automationId="order-search-button"
										>
											Search
										</Button>
									)}
									<Button
										onClick={() => setFiltersPopupOpen(true)}
										size="large"
										type="subtle"
										colour="grey"
										icon={{
											restIcon: falFilter,
											hoverIcon: farFilter,
											pressedIcon: fasFilter,
											selectedIcon: fadFilter,
											iconSecondaryColour: "var(--foregroundBrandOne)",
										}}
										selected={filtersPopupOpen}
									>
										Filters
									</Button>
								</div>
							</div>
						)}
						<InfoTable
							ghost={query.isLoading}
							columnData={{ data: desktopOrdersColumns, widths: {} }}
							mobileColumnData={{ data: mobileOrdersColumns, widths: {} }}
							useMobileColumns={width < ScreenSize.TabletLandscape}
							data={query.data || []}
							hasMoreRows={query.hasNextPage}
							fetchNextPage={query.fetchNextPage}
							shouldFetchNextPage={query.hasNextPage && !query.isFetchingNextPage}
							resultCallback={(row) => ActionPaths.ReviewResults(row?.patientId)}
							isExpanding={width >= ScreenSize.TabletLandscape}
							ExpandedRowComponent={ExpandingOrderRow}
							tableRef={tableRef}
							filtersCount={changedFiltersCount > 0 ? changedFiltersCount : undefined}
							onFiltersClick={width < 640 ? () => setFiltersPopupOpen(true) : undefined}
							automationId={"orders-table"}
							noDataMessage={query.isError ? "Error getting orders" : "No orders to show"}
						/>
					</>
				)}
			</div>
			<OrdersFilterDialog
				visible={filtersPopupOpen}
				setVisible={setFiltersPopupOpen}
				appliedFilters={appliedFilters}
				setAppliedFilters={updateAppliedFilters}
				defaultFilters={initialFilters}
			/>
		</div>
	);
}
