import { useAutoUpdateLastUsedAction } from "@streets-heaver/core";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import { useCreateAppointment, useCurrentClinicians } from "../../api/hooks";
import { DiscardChangesDialog, FormWrapper, NoAccessPage } from "../../components";
import { AddAppointmentForm } from "../../components/FormComponents";
import { dateTimeFormat } from "../../utils";
import { addAppointmentFollowUpDefaultValues, addAppointmentNewDefaultValues } from "./AddAppointmentValues";
import { transformAppointmentDataForAdd } from "./transformAppointmentData";

export const AddAppointment = ({ type }) => {
	const [appointmentType, setAppointmentType] = useState(type);
	const isFollowUp = appointmentType === "follow-up";

	useAutoUpdateLastUsedAction(isFollowUp ? "add-appointment-follow-up" : "add-appointment-new");

	const { userSecurityFlags } = useCurrentClinicians();
	const { mutateAsync: createAppointment, status: createStatus } = useCreateAppointment();

	const location = useLocation();
	const navigate = useNavigate();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: isFollowUp
			? addAppointmentFollowUpDefaultValues(location.state?.date, location.state?.time, location.state?.duration)
			: addAppointmentNewDefaultValues(location.state?.date, location.state?.time, location.state?.duration),
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		const transformedData = transformAppointmentDataForAdd(data, appointmentType);
		toast
			.promise(createAppointment(transformedData), {
				loading: {
					title: "Adding new appointment",
					subtitle: `Creating appointment for ${dateTimeFormat(transformedData?.bookingStart)}`,
				},
				success: {
					title: "Successfully created appointment",
					subtitle: `Appointment created`,
				},
				error: (e) => ({
					title: "Error creating appointment",
					subtitle: e?.response?.data?.detail ?? `Failed creating appointment. Please try again later.`,
				}),
			})
			.then(() => navigate(".."));
	};

	const onClose = () => navigate(-1);

	useEffect(() => {
		setAppointmentType(type);
		methods.reset();
	}, [type, methods]);

	if (!userSecurityFlags.includes("appointment-addappointment")) {
		return <NoAccessPage />;
	}

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Create an appointment"}>
					<FormWrapper.Body>
						<AddAppointmentForm type={appointmentType} />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Add appointment",
								automationId: "add-appointment-submit",
								disabled: createStatus === "pending",
							},
							secondaryButton: {
								onClick: methods.formState.isDirty ? () => setIsConfirmDiscardDialogVisible(true) : () => onClose(),
								children: "Cancel",
								automationId: "add-appointment-cancel",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
