import { Textbox } from "@streets-heaver/shui2";
import { useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useFormError } from "../../../../../utils";
import { PurchasersLookup } from "../../../lookups/PurchasersLookup";
import {
	authCodeSchemaError,
	membershipNumberRefinementError,
	membershipNumberSchemaError,
} from "../../../../../globals/messages";

export const Purchaser = ({ isEdit, automationId }) => {
	const form = useFormContext();

	const purchaserValue = form.watch("purchaser");

	const authCode = form.register("authCode", {
		validate: !purchaserValue?.isSelfPay
			? {
					regexCheck: (value) =>
						validateRegex(purchaserValue?.authCode?.validationPattern, purchaserValue?.authCode?.errorMessage, value),
					characterLimit: (value) => value?.length < 51 || authCodeSchemaError,
				}
			: {},
	});

	const membershipNum = form.register("membershipNumber", {
		validate: !purchaserValue?.isSelfPay
			? {
					regexCheck: (value) =>
						validateRegex(
							purchaserValue?.insuranceMembershipNo?.validationPattern,
							purchaserValue?.insuranceMembershipNo?.errorMessage,
							value,
						),
					requiredWithPurchaser: (value) =>
						(value && purchaserValue) || (purchaserValue && membershipNumberRefinementError),
					characterLimit: (value) => value?.length < 41 || membershipNumberSchemaError,
				}
			: {},
	});

	const authCodeError = useFormError(authCode.name).length > 0;
	const membershipNumError = useFormError(membershipNum.name).length > 0;

	return (
		<FormGroup>
			<PurchasersLookup isReadOnly={isEdit} automationId={automationId} />
			{purchaserValue && !purchaserValue?.isSelfPay && (
				<>
					<FormField label="Authorisation code" name={authCode.name} automationId={`${automationId}-authCode`}>
						<div style={{ maxWidth: "320px" }}>
							<Textbox
								type={"filledDarker"}
								inputName={authCode.name}
								onChange={authCode.onChange}
								onBlur={authCode.onBlur}
								reference={authCode.ref}
								size="large"
								isError={authCodeError}
								automationId={`${automationId}-authCode`}
							/>
						</div>
					</FormField>
					<FormField
						label="Insurance membership number"
						isMandatory={
							purchaserValue?.ediAuthorisationType === "Mandatory" || purchaserValue?.ediAuthorisationType === "EDI"
						}
						name={membershipNum.name}
						automationId={`${automationId}-membershipNumber`}
					>
						<div style={{ maxWidth: "320px" }}>
							<Textbox
								type={"filledDarker"}
								inputName={membershipNum.name}
								onChange={membershipNum.onChange}
								onBlur={membershipNum.onBlur}
								reference={membershipNum.ref}
								size="large"
								isError={membershipNumError}
								automationId={`${automationId}-membershipNumber`}
							/>
						</div>
					</FormField>
				</>
			)}
		</FormGroup>
	);
};

const validateRegex = (regexArray, message, value) => {
	const validate = !!regexArray?.find((regex) => new RegExp(regex).test(value));
	return validate || message;
};
