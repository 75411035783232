import { useMutation } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useMsal } from "@azure/msal-react";

export const useEditPatient = () => {
	const { currentDataSource } = useDataSources();
	const { instance } = useMsal();

	return useMutation({
		mutationKey: ["editPatient"],
		mutationFn: (data) => {
			return makeApiRequest(instance, `${currentDataSource?.DataSourceId}/edit`, {
				method: "put",
				body: data,
			});
		},
	});
};
