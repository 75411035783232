import { Button, MenuButton, Tooltips } from "@streets-heaver/shui2";
import classes from "./ServiceRequestRow.module.scss";
import { SeenRequest } from "../BadgeCells/BadgeCells";
import { shortDateFormat } from "../../../../utils/dateFormatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCheck,
	faClock,
	faEllipsis,
	faPrint as falPrint,
	faRectangleList as falRectangleList,
} from "@shfortawesome/pro-light-svg-icons";
import { faPrint as farPrint } from "@shfortawesome/pro-regular-svg-icons";
import { faPrint as fasPrint } from "@shfortawesome/pro-solid-svg-icons";
import { useCurrentClinicians } from "../../../../api/hooks/useClinicians";
import { useUpdateOrderStatus } from "../../../../api/hooks/useUpdateOrderStatus";
import { usePrintList } from "../../../../api/hooks/forms/usePrintList";
import { useNavigate } from "react-router";
import { createPortal } from "react-dom";
import clsx from "clsx";
import { getFormResultForPrint, getOrderForPrint } from "../../../../utils/getOrderForPrint/getOrderForPrint";

export const ServiceRequestRow = ({ request, resultCallback, type = "desktopTable" }) => {
	const isSeen = request?.status === "Seen";
	const isWidget = type === "widget";

	return (
		<div className={clsx(classes.requestRow, isWidget && classes.transparentRow)} data-testid="request-row">
			<div className={clsx(classes.info, isWidget ? classes.infoGrid : classes.infoFlex)}>
				<div className={classes.titleInfo}>
					<p className={classes.title} data-testid="request-name">
						{request?.serviceRequestName}
					</p>
					{getStatusIcon(request)}
					<SeenRequest isRead={isSeen} />
				</div>
				{request?.verifiedDate && (
					<p className={classes.reportVerified}>Report verified {shortDateFormat(request?.verifiedDate)}</p>
				)}
			</div>
			<ServiceRequestRowActions request={request} resultCallback={resultCallback} type={type} />
		</div>
	);
};

const getStatusIcon = (request) => {
	if (request?.reportable && !request?.hasReport) {
		return (
			<>
				<FontAwesomeIcon
					data-tooltipid={`serviceRequestRow-${request?.orderId}`}
					icon={faClock}
					style={{ color: "var(--backgroundMarigold)" }}
				/>
				{createPortal(
					<Tooltips
						tooltips={[
							{
								anchorSelect: `[data-tooltipid='serviceRequestRow-${request?.orderId}']`,
								content: <p>The order is pending because it requires a report.</p>,
								place: "bottom",
							},
						]}
					/>,
					document.body,
				)}
			</>
		);
	} else {
		return <FontAwesomeIcon icon={faCheck} style={{ color: "var(--foregroundGreen)" }} />;
	}
};

const ServiceRequestRowActions = ({ request, resultCallback, type }) => {
	const isWidget = type === "widget";
	const isMobileTable = type === "mobileTable";
	const isDesktopTable = type === "desktopTable";
	const isSeen = request?.status === "Seen";
	const isSentOrSeen = isSeen || request?.status === "Sent";
	const { userSecurityFlags } = useCurrentClinicians();
	const noActions = !request.hasResult && !request.hasReport;

	const navigate = useNavigate();
	const { mutate: markAsSeen, isPending: isMarkAsSeenPending } = useUpdateOrderStatus(isSeen ? "sent" : "seen");
	const { mutate: printOrder, isPending: printOrderPending } = usePrintList("orderservice");
	const { mutate: printFormResults, isPending: printFormResultsPending } = usePrintList("formresult");
	const canDownloadReport = userSecurityFlags.includes("orders-download");
	const canDownloadFormResults = userSecurityFlags.includes("formresults-download");
	const canMarkSeen = userSecurityFlags.includes("orders-markseen");
	const canViewVIPs = userSecurityFlags.includes("viewvippatients");

	const getServiceRequestEllipsisMenu = () => [
		{
			items: [
				{
					text: "Review results",
					icon: <FontAwesomeIcon icon={falRectangleList} />,
					onClick: (e) => {
						e.stopPropagation();
						navigate(resultCallback?.(request));
					},
					isDisabled: !request.hasResult,
				},
				{
					text: "Print order report",
					icon: <FontAwesomeIcon icon={falPrint} />,
					onClick: (e) => {
						e.stopPropagation();
						printOrder(getOrderForPrint(request));
					},
					isDisabled: printOrderPending || !request.hasReport || !canDownloadReport,
				},
				{
					text: "Print form results",
					icon: <FontAwesomeIcon icon={falPrint} />,
					onClick: (e) => {
						e.stopPropagation();
						printFormResults(getFormResultForPrint(request, canViewVIPs));
					},
					isDisabled: printFormResultsPending || !request.hasResult || !canDownloadFormResults,
				},
			],
		},
	];

	return (
		<div className={clsx(classes.actions, classes[type])}>
			{isDesktopTable && isSentOrSeen && (
				<Button
					size="small"
					disabled={!canMarkSeen || isMarkAsSeenPending}
					onClick={(e) => {
						e.stopPropagation();
						markAsSeen(request?.orderId);
					}}
				>
					{isSeen ? "Mark as unseen" : "Mark as seen"}
				</Button>
			)}
			{!isMobileTable && request.hasReport && (
				<Button
					size={isWidget ? "medium" : "small"}
					icon={{
						restIcon: falPrint,
						hoverIcon: farPrint,
						pressedIcon: fasPrint,
					}}
					disabled={
						!userSecurityFlags.includes(isWidget ? "patientview-ordersdownload" : "orders-download") ||
						printOrderPending
					}
					onClick={(e) => {
						e.stopPropagation();
						printOrder(getOrderForPrint(request));
					}}
				>
					{isWidget ? "" : "Print"}
				</Button>
			)}
			{isMobileTable && !noActions && (
				<MenuButton
					type="subtle"
					icon={{ restIcon: faEllipsis }}
					menuProps={{
						items: getServiceRequestEllipsisMenu(),
						hasIcons: true,
					}}
					tooltipText="Actions"
					ariaLabel="Actions for this order"
					portalTarget={document.body}
					noChevron
					automationId="mobileOrderActions"
				/>
			)}
		</div>
	);
};
