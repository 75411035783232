import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faCalendarDays, faClipboardList, faUsersRectangle } from "@shfortawesome/pro-duotone-svg-icons";
import { useCurrentClinicians, usePatientBanner } from "../../api/hooks";
import { ClinicianSwitchButton } from "../../components";
import { useDataSources } from "@streetsheaver/compucore";
import { formatObjectAsReversed } from "../../utils";
import { useParams } from "react-router";

const iconStyle = { height: 20, width: 20, padding: "2px", marginRight: 4, color: "var(--appColour)" };

export const useBreadcrumbs = (pathname, onClinicianSwitchClick) => {
	const { currentDataSource } = useDataSources();
	const { patientId } = useParams();
	const dataSourceId = currentDataSource?.DataSourceId;
	const { currentClinician, clinicians } = useCurrentClinicians();
	const clinicianName = clinicians?.find((clinician) => clinician.uniqueId === currentClinician)?.fullnameReverse;

	const pageName = pathname?.split("/")?.[2];

	const arrayOfBreadcrumbs = [
		{
			name: "Clinician",
			icon: <FontAwesomeIcon icon={faUserDoctor} style={iconStyle} />,
			navigation: { to: "/" + dataSourceId },
		},
		{
			name: { clinicianName },
			onClick: onClinicianSwitchClick,
			render: <>{currentClinician && <ClinicianSwitchButton clinicianName={clinicianName} />}</>,
		},
	];

	switch (pageName) {
		case "scheduler":
			arrayOfBreadcrumbs.push({
				name: "Scheduler",
				icon: <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "scheduler" },
			});
			break;
		case "mypatients":
			arrayOfBreadcrumbs.push({
				name: "My patients",
				icon: <FontAwesomeIcon icon={faUsersRectangle} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "mypatients" },
			});
			break;
		case "orders":
			arrayOfBreadcrumbs.push({
				name: "Orders",
				icon: <FontAwesomeIcon icon={faClipboardList} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "orders" },
			});
			break;
		case "patient-area":
			arrayOfBreadcrumbs.push({
				name: "Patient Area",
				navigation: { to: `/${dataSourceId}/patient-area/${patientId}` },
				render: <PatientBreadcrumb patientId={patientId} />,
			});
			break;
		default:
			break;
	}

	return arrayOfBreadcrumbs;
};

const PatientBreadcrumb = ({ patientId }) => {
	const { data } = usePatientBanner(patientId);
	const patientName = data ? formatObjectAsReversed(data) : null;
	return patientName ?? "Patient Area";
};
