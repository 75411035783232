import { Datebox, Field, Lookup } from "@streets-heaver/shui2";
import { useEffect, useState } from "react";
import dayjs from "dayjs/esm";
import { FiltersDialog } from "../FiltersDialog";
import classes from "./MyPatientsFilterDialog.module.scss";
import { useSitesLookup } from "../../../api/hooks/lookups/useSitesLookup";

export const MyPatientsFilterDialog = ({ appliedFilters, setAppliedFilters, defaultFilters, visible, setVisible }) => {
	const [siteInput, setSiteInput] = useState([]);
	const [dateFromInput, setDateFromInput] = useState(new Date().toISOString());
	const [dateToInput, setDateToInput] = useState(new Date().toISOString());

	const [siteSearchTerm, setSiteSearchTerm] = useState("");

	const sitesQuery = useSitesLookup(siteSearchTerm);
	const appliedDateFrom = appliedFilters.start?.value[0];
	const appliedDateTo = appliedFilters.end?.value[0];

	useEffect(() => {
		setSiteInput((prev) =>
			appliedFilters.site?.value.map((siteId) => ({
				name: prev?.find((site) => site.id === siteId)?.name,
				id: parseInt(siteId),
			})),
		);
		setDateFromInput(appliedDateFrom);
		setDateToInput(appliedDateTo);
	}, [appliedFilters, appliedDateFrom, appliedDateTo, visible]);

	function getSelectedFilters() {
		const selectedFilters = {};
		selectedFilters.site = appliedFilters.site.cloneWithNewValue(siteInput.map((site) => site.id));
		selectedFilters.start = appliedFilters.start.cloneWithNewValue(
			dateFromInput ? [dayjs(dateFromInput).startOf("day").format()] : [],
		);
		selectedFilters.end = appliedFilters.end.cloneWithNewValue(
			dateToInput ? [dayjs(dateToInput).endOf("day").format()] : [],
		);

		selectedFilters.status = appliedFilters.status;

		return selectedFilters;
	}

	return (
		<FiltersDialog
			defaultFilters={defaultFilters}
			getSelectedFilters={getSelectedFilters}
			setAppliedFilters={setAppliedFilters}
			setVisible={(isVisible) => {
				setSiteSearchTerm("");
				setVisible(isVisible);
			}}
			visible={visible}
		>
			<Field label="Site(s)">
				<Lookup
					searchBy={{ enabled: false }}
					searchQueryData={{
						...sitesQuery,
						data: sitesQuery?.data?.filter((site) => !siteInput.some((selectedSite) => selectedSite.id === site.id)),
					}}
					setSelected={setSiteInput}
					selected={siteInput || null}
					singleSelect={false}
					onSearchTermChange={setSiteSearchTerm}
					automationId="site-filter-input"
				/>
			</Field>
			<Field label={"Date"}>
				<div className={classes.daterange}>
					<Datebox
						type="filledDarker"
						key={`fromDatebox-${appliedDateFrom}`}
						customWidth={150}
						onSelected={(date) => date && setDateFromInput(date.toISOString())}
						size="large"
						showInitialDate={!!appliedDateFrom}
						defaultDate={appliedDateFrom && new Date(appliedDateFrom)}
						automationId="from-filter-input"
					/>
					<p>to</p>
					<Datebox
						type="filledDarker"
						key={`toDatebox-${appliedDateTo}`}
						customWidth={150}
						onSelected={(date) => setDateToInput(date.toISOString())}
						size="large"
						showInitialDate={!!appliedDateTo}
						defaultDate={appliedDateTo && new Date(appliedDateTo)}
						automationId="to-filter-input"
					/>
				</div>
			</Field>
		</FiltersDialog>
	);
};
