import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { useMsal } from "@azure/msal-react";

export const useOrderFormResults = (patientId, formComponentId, take) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useInfiniteQuery({
		queryKey: ["orderFormResults", currentClinician, patientId, formComponentId, take],
		initialPageParam: 0,
		queryFn: async ({ pageParam }) => {
			return await getOrderFormResults(
				instance,
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				formComponentId,
				pageParam,
				take,
			);
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && !!formComponentId && !isNaN(take),
	});
	return query;
};

export const getOrderFormResults = async (
	instance,
	dataSourceId,
	clinicianId,
	patientId,
	formComponentId,
	skip = 0,
	take = 12,
) => {
	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/orders/patient/${patientId}/results/${formComponentId}/history?skip=${skip}&take=${
			take + 1
		}`,
		{},
		"v2",
	);

	if (data?.request?.response) throw new Error("Getting form results failed");

	return {
		data: { ...data, results: data.results.slice(0, take) },
		nextPageIndex: data?.results?.length >= take + 1 ? skip + take : undefined,
	};
};
