import React from "react";
import ReactDOM from "react-dom/client";
import "@streets-heaver/shui2/SHUI2Styles.css";
import "@streets-heaver/core/streets-heaver-ui.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import { msalConfig } from "./authConfig";
import "./dayjsSetup";
import { PublicClientApplication } from "@azure/msal-browser";

const queryClient = new QueryClient();

export const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<MsalProvider instance={msalInstance}>
				<div id={"modal-portal-exit"}></div>
				<App />
			</MsalProvider>
		</QueryClientProvider>
	</React.StrictMode>,
);
