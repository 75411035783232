import { useAutoUpdateLastUsedAction } from "@streets-heaver/core";
import { Card } from "@streets-heaver/shui2";
import { useState } from "react";
import { Outlet } from "react-router";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { AppointmentCalendar, NoAccessPage, SchedulerSettings } from "../../components";
import { BookingPreviewPanel } from "../../components/BookingPreviewPanel/BookingPreviewPanel";
import { useSelectedBooking } from "../../utils/hooks/useSelectedBooking";
import classes from "./Scheduler.module.scss";

export default function Scheduler() {
	useAutoUpdateLastUsedAction("scheduler");

	const { userSecurityFlags } = useCurrentClinicians();
	const [dayRange, setDayRange] = useState(parseInt(localStorage.getItem("compucare.calendarDayRange")) || 7);
	const [startTime, setStartTime] = useState(parseInt(localStorage.getItem("compucare.calendarStartTime")) || 8);
	const [timescale, setTimescale] = useState(parseInt(localStorage.getItem("compucare.calendarInterval")) || 2);

	const { setSelectedBooking } = useSelectedBooking();

	return (
		<div className={classes.scheduler} data-testid={"pageScheduler"}>
			<BookingPreviewPanel />
			{!userSecurityFlags.includes(`scheduler`) ? (
				<NoAccessPage />
			) : (
				<div className={classes.schedulerBlock}>
					<Outlet />
					<div className={classes.schedulerSettings}>
						<SchedulerSettings
							timescale={timescale}
							setTimescale={setTimescale}
							startTime={startTime}
							setStartTime={setStartTime}
							dayRange={dayRange}
							setDayRange={setDayRange}
						/>
					</div>
					<Card className={classes.schedulerCard}>
						<AppointmentCalendar
							timescale={timescale}
							setTimescale={setTimescale}
							startTime={startTime}
							setStartTime={setStartTime}
							dayRange={dayRange}
							setDayRange={setDayRange}
							setSelectedBooking={setSelectedBooking}
							isSchedulerPage
						/>
					</Card>
				</div>
			)}
		</div>
	);
}
