import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { useState } from "react";
import { DiscardChangesDialog, EditAppointmentFormContent, FormWrapper } from "../../components";
import { transformAppointmentDataForEdit } from "./transformAppointmentData";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { useEditAppointment } from "../../api/hooks";
import { dateTimeFormat } from "../../utils";

export const EditAppointmentForm = ({ data }) => {
	const navigate = useNavigate();
	const { mutateAsync: editAppointment, status: editStatus } = useEditAppointment();
	const automationId = "edit-appointment";
	const isDue = data?.booking?.status === "Booked";

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...data?.form },
	});

	const [isConfirmDiscardDialogVisible, setIsConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (editedData) => {
		toast
			.promise(editAppointment(transformAppointmentDataForEdit(editedData, data)), {
				loading: {
					title: "Editing appointment",
					subtitle: `Editing appointment at ${dateTimeFormat(data?.booking?.start)}`,
				},
				success: {
					title: "Appointment edited",
					subtitle: `Successfully edited appointment at ${dateTimeFormat(data?.booking?.start)}`,
				},
				error: (e) => ({
					title: "Error editing appointment",
					subtitle: e?.response?.data?.detail ?? `Failed editing appointment. Please try again later.`,
				}),
			})
			.then(() => navigate(".."));
	};

	const onClose = () => navigate(-1);

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Edit an appointment"} fixedHeight>
					<FormWrapper.Body>
						<EditAppointmentFormContent
							patient={data?.patient}
							episodeOfCare={data?.episodeOfCare}
							booking={data?.booking}
							automationId={automationId}
						/>
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Save",
								automationId: `${automationId}-submit`,
								disabled: editStatus === "pending",
							},
							secondaryButton: {
								onClick: () => {
									methods.formState.isDirty ? setIsConfirmDiscardDialogVisible(true) : navigate(-1);
								},
								children: "Cancel",
								automationId: `${automationId}-cancel`,
							},
							tertiaryButton: {
								navigation: { to: `../${ActionPaths.CancelAppointmentPreselected(data?.form?.bookingUniqueId)}` },
								children: "Cancel appointment",
								disabled: !isDue,
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setIsConfirmDiscardDialogVisible}
			/>
		</>
	);
};
