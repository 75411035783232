import { RecentActionsProvider, VersionProvider } from "@streets-heaver/core";
import { MotionConfig } from "framer-motion";
import { Outlet } from "react-router";
import { isTesting } from "../../globals/constants";
import { ClinicianProvider } from "../../providers/ClinicianProvider";
import { SelectedBookingProvider } from "../../providers/SelectedBookingProvider";
import { ClinicianJournalAPIProvider } from "../../providers/ClinicianJournalAPIProvider";
import Frame from "./Frame";
import classes from "./Layout.module.scss";

function Layout() {
	return (
		<MotionConfig reducedMotion={isTesting ? "always" : "user"}>
			<div style={isTesting ? { transitionDelay: "0s !important", transitionDuration: "0s !important" } : {}}>
				<VersionProvider>
					<ClinicianProvider>
						<ClinicianJournalAPIProvider>
							<RecentActionsProvider>
								<SelectedBookingProvider>
									<div className={classes.outerContainer}>
										<Frame>
											<div id="contentContainer" className={classes.innerContainer}>
												<Outlet />
											</div>
										</Frame>
									</div>
								</SelectedBookingProvider>
							</RecentActionsProvider>
						</ClinicianJournalAPIProvider>
					</ClinicianProvider>
				</VersionProvider>
			</div>
		</MotionConfig>
	);
}

export default Layout;
