import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";
import { makeApiRequest } from "../../makeApiRequest";
import { useMsal } from "@azure/msal-react";
import { useMemo } from "react";

export const useSitesLookup = (search, limitToAccessible) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "code-table", "sites", currentClinician, search],
		queryFn: async ({ pageParam }) => {
			return await getSites(instance, currentDataSource?.DataSourceId, currentClinician, pageParam, 12, search, [], limitToAccessible);
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		refetchOnWindowFocus: false,
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});

	const flatData = useMemo(() => query.data?.pages?.flatMap((page) => page?.data), [query?.data]);

	return { ...query, data: flatData };
};

export const useSitesLookupFromIds = (ids) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const { instance } = useMsal();

	return useQuery({
		queryKey: ["sitesLookupFromIds", currentClinician, ids],
		queryFn: async () => {
			if (!ids?.length) return [];
			return await getSites(instance, currentDataSource?.DataSourceId, currentClinician, 0, ids.length, null, ids);
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
		staleTime: 60000,
	});
};

const getSites = async (instance, dataSourceId, clinicianId, skip = 0, take = 12, searchText = "", sites = [], limitToAccessible) => {
	const urlParams = new URLSearchParams();
	urlParams.set('skip', skip)
	urlParams.set('take', take+1)
	if(searchText && searchText !== '') urlParams.set('searchText', searchText)
	for (const site of sites) {
		urlParams.append('site', site)
	}

	if(limitToAccessible !== undefined) urlParams.set('limitToAccessible', limitToAccessible)

	const data = await makeApiRequest(
		instance,
		`${dataSourceId}/clinician/${clinicianId}/lookup/sites?${urlParams.toString()}`,
	);
	return {
		data: data.slice(0, take).map((site) => ({ ...site, id: site?.siteId })),
		nextPageIndex: data?.length >= take + 1 ? skip + take : undefined,
	};
};
